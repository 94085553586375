<template>
  <v-card class="card-shadow border-radius-xl mt-2 pb-2">
    <div class="card-header-padding">
      <div class="d-flex align-center">
        <div>
          <h5 class="font-weight-bold text-h5 text-grt mb-0">選擇模式</h5>
          <p class="text-body">
            系統提供兩種模式 一個是一般模式 一個是夜間模式 可以自由切換
          </p>
        </div>
      </div>
    </div>

    <div class="px-4 pb-2 pt-0">
      <v-btn-toggle
        v-model="toggle_exclusive"
        tile
        color="deep-purple accent-3 mx-auto"
        class="d-flex justify-space-around"
        group
        mandatory
      >
        <v-btn
          :ripple="false"
          class="
            border border-2 border-secondary border-radius-md
            pa-15
            mb-12
          "
          icon
          @click="onToggleChange(0)"
        >
          <v-icon x-large>mdi-weather-night</v-icon>

          <div class="mt-16 position-absolute">
            <h6
              class="
                text-h6 text-typo
                font-weight-bold
                mb-n16
                mt-12
                text-capitalize
              "
            >
              夜間
            </h6>
          </div>
        </v-btn>

        <v-btn
          :ripple="false"
          class="
            border border-2 border-secondary border-radius-md
            pa-15
            mb-12
          "
          icon
          @click="onToggleChange(1)"
        >
          <v-icon x-large>mdi-weather-sunny</v-icon>
          <div class="mt-16 position-absolute">
            <h6
              class="
                text-h6 text-typo
                font-weight-bold
                mb-n16
                mt-12
                text-capitalize
              "
            >
              一般
            </h6>
          </div>
        </v-btn>
      </v-btn-toggle>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ThemeMode",
  data() {
    return {
      toggle_exclusive: -1,
    };
  },
  mounted() {
    console.log("mounted", this.$vuetify.theme.dark);
    if (this.$vuetify.theme.dark) {
      this.toggle_exclusive = 0;
    } else {
      this.toggle_exclusive = 1;
    }
  },
  methods: {
    onToggleChange(val) {
      console.log("toggle_exclusive", val);
      if (val == 0) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
      const theme = this.$vuetify.theme.dark ? "dark" : "white";

      this.$emit("updateSidebarTheme", theme);
    },
  },
};
</script>
